import React from "react"
import SeoHeaders from "../components/seo-headers/seo-headers"
import LayoutMain from "../components/layout-main/layout-main"
import HeroUnit from "../components/hero-unit/hero-unit"

import "./about.scss"

const AboutPage = () => (
  <LayoutMain className="page page-about">
    <SeoHeaders title="About" keywords={[`about`]} />
    <HeroUnit pageTitle="About Us" />
    <section>
      <div className="content-section">
        <div className="content-section-inner">
          <div className="page-content">
            <h2 className="main-heading">About Wildfire Digital Marketing</h2>
          </div>
        </div>
      </div>
    </section>
  </LayoutMain>
)

export default AboutPage
